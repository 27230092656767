@font-face {
    font-family: "odsicons";
    src: url("./odsicons.eot?168079230830d38941416df5ce452e87#iefix") format("embedded-opentype"),
url("./odsicons.woff2?168079230830d38941416df5ce452e87") format("woff2"),
url("./odsicons.woff?168079230830d38941416df5ce452e87") format("woff");
}

span[class^="odsi-"]:before, span[class*=" odsi-"]:before {
    font-family: odsicons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.odsi-2-chevron-left:before {
    content: "\f101";
}
.odsi-2-chevron-right:before {
    content: "\f102";
}
.odsi-add:before {
    content: "\f103";
}
.odsi-addphoto:before {
    content: "\f104";
}
.odsi-app:before {
    content: "\f105";
}
.odsi-arrow-begin-2:before {
    content: "\f106";
}
.odsi-arrow-begin:before {
    content: "\f107";
}
.odsi-arrow-down-nav:before {
    content: "\f108";
}
.odsi-arrow-end-2:before {
    content: "\f109";
}
.odsi-arrow-end:before {
    content: "\f10a";
}
.odsi-arrow-left-nav:before {
    content: "\f10b";
}
.odsi-arrow-left:before {
    content: "\f10c";
}
.odsi-arrow-right:before {
    content: "\f10d";
}
.odsi-arrow-up-nav:before {
    content: "\f10e";
}
.odsi-attach:before {
    content: "\f10f";
}
.odsi-bell:before {
    content: "\f110";
}
.odsi-business-deal-handshake:before {
    content: "\f111";
}
.odsi-calendar:before {
    content: "\f112";
}
.odsi-check-2:before {
    content: "\f113";
}
.odsi-check:before {
    content: "\f114";
}
.odsi-chevron-down:before {
    content: "\f115";
}
.odsi-chevron-up:before {
    content: "\f116";
}
.odsi-close:before {
    content: "\f117";
}
.odsi-contact-phone:before {
    content: "\f118";
}
.odsi-currency-euros:before {
    content: "\f119";
}
.odsi-doctor:before {
    content: "\f11a";
}
.odsi-dot:before {
    content: "\f11b";
}
.odsi-download:before {
    content: "\f11c";
}
.odsi-edit:before {
    content: "\f11d";
}
.odsi-ellipsis:before {
    content: "\f11e";
}
.odsi-equal:before {
    content: "\f11f";
}
.odsi-eye-closed:before {
    content: "\f120";
}
.odsi-eye-open:before {
    content: "\f121";
}
.odsi-files-2-items:before {
    content: "\f122";
}
.odsi-files-check:before {
    content: "\f123";
}
.odsi-files-image-file-landscape:before {
    content: "\f124";
}
.odsi-files-new-item:before {
    content: "\f125";
}
.odsi-files-single-item-2:before {
    content: "\f126";
}
.odsi-files-single-item:before {
    content: "\f127";
}
.odsi-filter:before {
    content: "\f128";
}
.odsi-hamburger-justify:before {
    content: "\f129";
}
.odsi-hamburger-left:before {
    content: "\f12a";
}
.odsi-hamburger-right:before {
    content: "\f12b";
}
.odsi-import:before {
    content: "\f12c";
}
.odsi-infinity:before {
    content: "\f12d";
}
.odsi-info:before {
    content: "\f12e";
}
.odsi-lock-closed:before {
    content: "\f12f";
}
.odsi-lock-open:before {
    content: "\f130";
}
.odsi-minus:before {
    content: "\f131";
}
.odsi-nav-cast:before {
    content: "\f132";
}
.odsi-nav-help:before {
    content: "\f133";
}
.odsi-nav-home:before {
    content: "\f134";
}
.odsi-nav-lecteur:before {
    content: "\f135";
}
.odsi-off:before {
    content: "\f136";
}
.odsi-refresh:before {
    content: "\f137";
}
.odsi-search:before {
    content: "\f138";
}
.odsi-selector-copy-1:before {
    content: "\f139";
}
.odsi-selector:before {
    content: "\f13a";
}
.odsi-settings:before {
    content: "\f13b";
}
.odsi-soft-turn-right:before {
    content: "\f13c";
}
.odsi-sort:before {
    content: "\f13d";
}
.odsi-sorting:before {
    content: "\f13e";
}
.odsi-star:before {
    content: "\f13f";
}
.odsi-superieur-ou-egal:before {
    content: "\f140";
}
.odsi-ticket:before {
    content: "\f141";
}
.odsi-trash:before {
    content: "\f142";
}
.odsi-upload-cloud:before {
    content: "\f143";
}
.odsi-upload:before {
    content: "\f144";
}
.odsi-users-partner:before {
    content: "\f145";
}
.odsi-users-single:before {
    content: "\f146";
}
.odsi-users-team:before {
    content: "\f147";
}
.odsi-waiting:before {
    content: "\f148";
}
.odsi-warning:before {
    content: "\f149";
}
